import React from "react";

import Layout from "../layout/layout";

export default () => (
    <Layout>
        <h3>This Must be the Place</h3>
        <p>Still under construction here, maybe later.</p>
    </Layout>
);
